<script setup lang="ts">
import { Style3d, type Style3dOptions } from '@repo/style3d';
import { useStyle3dStore } from '@repo/stores';

const options: Style3dOptions = {
  displayLevel: 1,
  backgroundColor: '#31363B'
};

const style3dStore = useStyle3dStore();

const onLoaded = () => {
  style3dStore.loaded = true;
};

const onReady = (isReady: boolean) => {
  style3dStore.ready = isReady;
};
</script>
<template>
  <div class="viewer-container">
    <Style3d
      src="//cloudknit-3d.oss-cn-shanghai.aliyuncs.com/lib/Style3D.umd.min.js"
      :options="options"
      @loaded="onLoaded"
      @ready="onReady"
    />
  </div>
</template>
<style scoped>
.viewer-container {
  width: 100%;
  height: 100%;
}
</style>
