
import type { App } from 'vue';
import { i18n, loadI18nMessages } from './i18n';

const $t = i18n.global.t;

export type SupportedLanguagesType = 'en' | 'zh';

async function setupI18n(app: App,defaultLocale:SupportedLanguagesType) {
  app.use(i18n);
  await loadI18nMessages(defaultLocale);
}

export { $t, setupI18n };
export { useI18n } from 'vue-i18n';