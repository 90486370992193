import { unref } from "vue";
import { createI18n } from "vue-i18n";
import type { Locale } from 'vue-i18n';

type ImportLocaleFn = () => Promise<{ default: Record<string, string> }>;

const loadedLanguages = new Set<string>();

export const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: '',
  messages: {},
})

const modules = import.meta.glob('./langs/*.json');

const localesMap: Record<Locale, ImportLocaleFn> = {};

for (const [path, loadLocale] of Object.entries(modules)) {
  const key = path.match(/([\w-]*)\.(yaml|yml|json)/)?.[1];
  if (key) {
    localesMap[key] = loadLocale as ImportLocaleFn;
  }
}

export async function loadI18nMessages(lang: Locale) {
  if (unref(i18n.global.locale) === lang) {
    return setI18nLanguage(lang);
  }

  if (loadedLanguages.has(lang)) {
    return setI18nLanguage(lang);
  }

  const messages = await localesMap[lang]?.();
  if(messages?.default){
    i18n.global.setLocaleMessage(lang, messages.default);
  }
  loadedLanguages.add(lang);
  return setI18nLanguage(lang);
}


export function setI18nLanguage(locale: Locale) {
  i18n.global.locale.value = locale;

  document?.querySelector('html')?.setAttribute('lang', locale);
}